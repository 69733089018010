<template>
  <div>
    <b-modal
      id="trackModal"
      v-model="shipShow"
      ok-only
      :ok-title="$t('common.close')"
      modal-class="modal-primary"
      centered
      size="xl"
      title="等待仓库取件运单"
      @close="handleClose"
    >
      <b-row class="mb-3 mt-3">
        <b-col md="3">
          <b-form-group>
            <b-form-input
              v-model="sForm.shipment_id"
              placeholder="运单ID"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <b-form-input
              v-model="sForm.pre"
              placeholder="预报单"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <b-form-input
              v-model="sForm.client_id"
              placeholder="客户ID"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <b-form-input
              v-model="sForm.line"
              placeholder="线路"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <b-form-input
              v-model="sForm.country"
              placeholder="国家"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
          >
            搜索
          </b-button>
        </b-col>
      </b-row>
      <div class="mb-1 d-flex justify-content-start align-items-center">
        <b-form-checkbox
          v-model="selectAll"
          value="A"
          class="mr-2"
          plain
        >
          选择20个运单
        </b-form-checkbox>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="handleSend"
        >
          发货
        </b-button>
      </div>
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="items"
        :fields="fields"
        responsive="sm"
        @row-selected="onRowSelected"
      >
        <!-- eslint-disable-next-line -->
        <template #cell(action)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
          >
            查看
          </b-button>
          <span>丨</span>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
          >
            打印运单
          </b-button>
        </template>
      </b-table>
      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          size="md"
          class="float-right"
          @click="handleClose"
        >
          {{ $t('common.close') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="sendShow"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      title="发货单"
      @close="handleSendClose"
    >
      <b-row class="mb-3 mt-2">
        <b-col md="4">
          <span>运单数量 7</span>
        </b-col>
        <b-col md="4">
          <span>包裹数量 8</span>
        </b-col>
        <b-col md="4">
          <span>计费重量 9kg</span>
        </b-col>
      </b-row>
      <b-table
        :items="sendItems"
        :fields="sendFields"
        responsive
        class="mb-3"
      />
      <b-form-group
        label-for="input-default"
      >
        <div class="d-flex justify-content-start align-items-center">
          <div class="batch-name">
            发货批次名称<span>*</span>:
          </div>
          <b-form-input
            id="input-default"
            v-model="batchName"
            style="width:40%"
            placeholder="发货批次名称"
          />
        </div>
      </b-form-group>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          size="md"
          class="float-right"
          @click="handleInvoice"
        >
          确认并生成发货单
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, BRow, BCol, BFormInput, BFormGroup, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton, BModal, BRow, BCol, BFormInput, BFormGroup, BTable, BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sendShow: false,
      shipShow: false,
      sForm: {
        shipment_id: '',
        pre: '',
        client_id: '',
        line: '',
        country: '',
      },
      selectAll: [],
      selectMode: 'multi',
      selected: [],
      items: [{
        shipment_id: 'AU1SPM0020030',
      }, {
        shipment_id: 'AU1SPM0020031',
      }],
      fields: [
        {
          key: 'shipment_id',
          label: '运单ID',
        },
        {
          key: 'pre',
          label: '预报单',
        },
        {
          key: 'client_id',
          label: '客户',
        },
        {
          key: 'receiver',
          label: '收件人',
        },
        {
          key: 'destination',
          label: '目的地',
        },
        {
          key: 'line',
          label: '线路选择',
        },
        {
          key: 'freight',
          label: '运费',
        },
        {
          key: 'qty',
          label: '数量',
        },
        {
          key: 'weight',
          label: '重量',
        },
        {
          key: 'status',
          label: '运单状态',
        },
        {
          key: 'create_date',
          label: '创建日期',
        },
        {
          key: 'action',
          label: '操作',
        },
      ],
      sendFields: [{
        key: 'country',
        label: '国家或地区',
      }, {
        key: 'qty',
        label: '运单数量',
      }, {
        key: 'parcel_qty',
        label: '包裹数量',
      }, {
        key: 'chargeable_weight',
        label: '计费重量',
      }],
      sendItems: [{
        country: 'china',
      }],
      batchName: '',
    }
  },
  watch: {
    show: {
      handler(val) {
        this.shipShow = val
      },
      immediate: true,
    },
    selectAll(val) {
      if (val[0] === 'A') {
        this.$refs.selectableTable.selectAllRows()
      } else {
        this.$refs.selectableTable.clearSelected()
      }
    },
  },
  methods: {
    handleClose() {
      this.selected = []
      this.selectAll = []
      this.shipShow = false
      this.$emit('close')
    },
    onRowSelected(items) {
      this.selected = items
    },
    handleSend() {
      if (this.selected.length) {
        this.handleClose()
        this.sendShow = true
      } else {
        this.$bvModal
          .msgBoxConfirm('请选择至少一个运单', {
            cancelVariant: 'outline-danger',
            centered: true,
            buttonSize: 'sm',
          })
      }
    },
    handleInvoice() {
      // console.log('生成发货单')
    },
    handleSendClose() {
      this.batchName = ''
      this.sendShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
.batch-name{
  width:20%;
  span{
    color:red;
  }
}
</style>
