<template>
  <div
    class="list"
    style="height: inherit"
  >
    <div class="ecommerce-searchbar mt-2">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="pageInfo.keyword"
              placeholder="Search Product"
              class="search-product"
              @keyup.enter="handleSearchEnter"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
    <!-- Prodcuts -->
    <section class="mt-4">
      <!-- Sort Button -->
      <b-dropdown
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :text="sortByTable.text"
        right
        variant="outline-primary"
      >
        <b-dropdown-item
          v-for="sortOption in sortByTableOptions"
          :key="sortOption.value"
          @click="sortByTable=sortOption"
        >
          {{ sortOption.text }}
        </b-dropdown-item>
      </b-dropdown>
      <b-card class="mt-2">
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :fixed-header="true"
          max-height="600px"
          style-class="vgt-table"
          :rtl="direction"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageInfo.perPage
          }"
          @on-page-change="onPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: invoice_no -->
            <span
              v-if="props.column.field === 'invoice_no'"
              class="text-nowrap"
              @click="$router.push(`/bill/detail/${props.row.invoice_no}`)"
            >
              <span class="text-nowrap blue">{{ props.row.invoice_no }}</span>
            </span>

            <!-- Column: status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status.substring(0, 1).toUpperCase() + props.row.status.substring(1) }}
              </b-badge>
            </span>

            <!-- Column: amount -->
            <span
              v-else-if="props.column.field === 'amount'"
              class="text-nowrap"
            >
              <span class="text-nowrap">AU${{ props.row.amount }}</span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="padding:5px"
                  variant="flat-primary"
                >
                  支付
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="padding:5px"
                  variant="flat-primary"
                  @click="waitShow = true"
                >
                  查看
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="padding:5px"
                  variant="flat-danger"
                >
                  下载invoice
                </b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageInfo.perPage"
                  :options="['10','20','30','50']"
                  class="mx-1"
                  @change="handlePerPageChange"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ pageInfo.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="pageInfo.total"
                  :per-page="pageInfo.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </section>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shipment-left-filter-sidebar
        :filters="filters"
        @handleAll="handleAll"
        @handlePay="handlePay"
        @handleSubmitFilter="handleSubmitFilter"
      />
    </portal>
    <wait
      :show="waitShow"
      @close="waitShow = false"
    />
  </div>
</template>
<script>
import {
  BDropdown, BDropdownItem, BRow, BCol, BInputGroup, BInputGroupAppend,
  BFormInput, BBadge, BPagination, BFormSelect, BCard, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ShipmentLeftFilterSidebar from '../shipment/components/shipmentLeftFilterSidebar.vue'
import Wait from './components/wait.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BBadge,
    BPagination,
    BFormSelect,
    BCard,
    BButton,
    ShipmentLeftFilterSidebar,
    VueGoodTable,
    Wait,
  },
  data() {
    return {
      waitShow: false,
      filters: {
        total: 0,
      },
      sortBy: { text: '时间', value: '1' },
      sortByOptions: [
        { text: '时间', value: '1' },
        { text: '价格', value: '2' },
      ],
      sortByTable: { text: '变更为已支付', value: '1' },
      sortByTableOptions: [
        { text: '变更为已支付', value: '1' },
        { text: '价格', value: '2' },
      ],
      dir: false,
      columns: [
        {
          label: '账单日期',
          field: 'bill_date',
        },
        {
          label: 'Invoice No',
          field: 'invoice_no',
        },
        {
          label: 'Invoice Peroid',
          field: 'invoice_peroid',
        },
        {
          label: 'Invoice Due date',
          field: 'invoice_due',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: '操作',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      status: [{
        1: 'paid',
        2: 'unpaid',
      },
      {
        1: 'light-success',
        2: 'light-danger',
      }],
      pageInfo: {
        page: 1,
        perPage: 10,
        total: 1,
        keyword: '',
        status: '',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        paid      : 'light-success',
        unpaid : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    // eslint-disable-next-line
    'pageInfo.keyword': function (val) {
      if (!val) {
        this.initState()
      }
    },
  },
  created() {
    this.initState()
  },
  methods: {
    // eslint-disable-next-line
    async initState(form = {}) {
      try {
        const data = {
          code: 200,
          data: {
            counts: 10,
            shipments: [{
              bill_date: '13 Sep 2021',
              invoice_no: 'INV1646465',
              invoice_peroid: '06/09/21 - 12/09/21',
              invoice_due: '18 Sep 2021',
              status: 'unpaid',
              amount: '20.00',
            }, {
              bill_date: '13 Sep 2021',
              invoice_no: 'INV1646465',
              invoice_peroid: '06/09/21 - 12/09/21',
              invoice_due: '18 Sep 2021',
              status: 'paid',
              amount: '20.00',
            }],
          },
        }
        if (data.code === 200) {
          this.rows = data.data.shipments
          this.pageInfo.total = data.data.counts
          if (this.pageInfo.status === '') {
            this.filters.total = data.data.counts
          }
        } else {
          this.$bvToast.toast(data.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },
    handlePerPageChange(val) {
      this.pageInfo.perPage = val
      this.initState()
    },
    onPageChange(params) {
      this.pageInfo.page = params.currentPage
      this.initState()
    },
    handleSearchEnter() {
      this.initState()
    },
    resetPageInfo() {
      this.pageInfo.page = 1
      this.pageInfo.perPage = 10
      this.pageInfo.keyword = ''
      this.pageInfo.status = ''
    },
    handleAll() {
      this.resetPageInfo()
      this.initState()
    },
    handlePay() {
      this.resetPageInfo()
      this.pageInfo.status = 'pay_now'
      this.initState()
    },
    handleSubmitFilter(val) {
      this.initState(val)
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
.card-wrap{
  padding: 140px 0;
}
.number-box{
  margin: 200px;
}
.show-text{
  display:block;
  word-break: keep-all;
  margin-right:10px;
}
.list ::v-deep table.vgt-table td{
  vertical-align: middle;
}
.list ::v-deep ul.dropdown-menu.dropdown-menu-right.show{
  z-index:11;
}
.blue{
  color: #7367f0;
  text-decoration: underline;
  cursor: pointer;
}
</style>
